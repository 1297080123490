import React from 'react';
import './Logo.scss';

function Logo(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 286.2 176.84" className={props.classes}>
			<defs>
				<linearGradient
					id="linear-gradient"
					x1="-23.72"
					y1="23.72"
					x2="124.8"
					y2="172.24"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset="0.01" stopColor="#edb658" />
					<stop offset="1" stopColor="#c1993e" />
				</linearGradient>
				<linearGradient
					id="linear-gradient-2"
					x1="-1316.81"
					y1="-12.71"
					x2="-1080.05"
					y2="224.05"
					gradientTransform="matrix(-0.41, 0, 0, 1, -252.46, 0)"
					href="#linear-gradient"
				/>
			</defs>
			<g id="Layer_2" data-name="Layer 2">
				<g id="Layer_1-2" data-name="Layer 1">
					<polygon
						id="left-horn"
						className="cls-1"
						points="0 0 18.6 114.92 47 163.98 82.8 176.84 120.2 176.84 82.8 163.98 58.6 146.57 58.4 96.94 47.2 131.54 35.6 107.21 30.4 8.31 25.6 93.36 14.6 22.35 14.2 48.66 0 0"
					/>
					<polygon
						id="right-horn"
						className="cls-2"
						points="286.2 0 267.6 114.92 239.2 163.98 203.4 176.84 166 176.84 203.4 163.98 227.6 146.57 227.8 96.94 239 131.54 250.6 107.21 255.8 8.31 260.6 93.36 271.6 22.35 272 48.66 286.2 0"
					/>
					<g id="fullstackmac-text" style={props.style}>
						<path className="cls-3" d="M59.69,75.57H48.41V86.88h-2V62.71h15v1.72H48.41v9.42H59.69Z" />
						<path
							className="cls-3"
							d="M76.22,84.6q-1.78,2.61-5.73,2.61a5.55,5.55,0,0,1-4.36-1.67A7.28,7.28,0,0,1,64.6,80.6V68.92h2V80.35q0,5.13,4.15,5.13c2.87,0,4.7-1.19,5.46-3.56v-13h2v18H76.26Z"
						/>
						<path className="cls-3" d="M85.57,86.88h-2V61.38h2Z" />
						<path className="cls-3" d="M93.19,86.88h-2V61.38h2Z" />
						<path
							className="cls-3"
							d="M109.34,82.31a2.81,2.81,0,0,0-1.19-2.37,9,9,0,0,0-3.57-1.4,16,16,0,0,1-3.71-1.17,4.85,4.85,0,0,1-2-1.6,4,4,0,0,1-.64-2.29A4.25,4.25,0,0,1,100.05,70a7.18,7.18,0,0,1,4.54-1.39,7.41,7.41,0,0,1,4.83,1.49A4.77,4.77,0,0,1,111.24,74h-2a3.22,3.22,0,0,0-1.32-2.63,6,6,0,0,0-6.47-.17,2.7,2.7,0,0,0-1.19,2.27,2.45,2.45,0,0,0,1,2.08,10.54,10.54,0,0,0,3.6,1.35,17.3,17.3,0,0,1,3.92,1.31,5.12,5.12,0,0,1,1.93,1.68,4.62,4.62,0,0,1-1.2,6,7.82,7.82,0,0,1-4.79,1.36,8.07,8.07,0,0,1-5.09-1.52,4.67,4.67,0,0,1-2-3.84h2a3.45,3.45,0,0,0,1.47,2.71,6,6,0,0,0,3.58,1,5.63,5.63,0,0,0,3.35-.91A2.73,2.73,0,0,0,109.34,82.31Z"
						/>
						<path
							className="cls-3"
							d="M118.89,64.32v4.6h3.72v1.62h-3.72v12a3.72,3.72,0,0,0,.54,2.22,2.08,2.08,0,0,0,1.8.73,12.2,12.2,0,0,0,1.61-.16l.08,1.61a6.39,6.39,0,0,1-2.12.28,3.51,3.51,0,0,1-3-1.19,5.57,5.57,0,0,1-.93-3.49v-12h-3.31V68.92h3.31v-4.6Z"
						/>
						<path
							className="cls-3"
							d="M138.06,86.88a9.62,9.62,0,0,1-.38-2.51,6.88,6.88,0,0,1-2.66,2.1,8.23,8.23,0,0,1-3.43.74,6,6,0,0,1-4.19-1.44,4.72,4.72,0,0,1-1.61-3.66A4.82,4.82,0,0,1,128,78a10.53,10.53,0,0,1,6.08-1.52h3.6v-2a4,4,0,0,0-1.18-3,4.94,4.94,0,0,0-3.46-1.1,5.47,5.47,0,0,0-3.44,1.06,3.15,3.15,0,0,0-1.36,2.56l-2,0a4.63,4.63,0,0,1,2-3.71,7.71,7.71,0,0,1,4.9-1.57,7,7,0,0,1,4.74,1.51,5.4,5.4,0,0,1,1.78,4.19v8.5a10.41,10.41,0,0,0,.55,3.9v.2Zm-6.24-1.43a6.66,6.66,0,0,0,3.56-1,5.6,5.6,0,0,0,2.28-2.57V78h-3.55a9,9,0,0,0-4.65,1.09,3.27,3.27,0,0,0-1.67,2.9,3.21,3.21,0,0,0,1.12,2.51A4.23,4.23,0,0,0,131.82,85.45Z"
						/>
						<path
							className="cls-3"
							d="M151.58,85.53a5.22,5.22,0,0,0,3.47-1.17,4.08,4.08,0,0,0,1.52-3h1.91a5.58,5.58,0,0,1-1,3,6.5,6.5,0,0,1-2.51,2.12,7.59,7.59,0,0,1-3.36.76A7,7,0,0,1,146,84.75a10,10,0,0,1-2.06-6.6v-.6a11.23,11.23,0,0,1,.93-4.7,7.12,7.12,0,0,1,2.66-3.15,7.38,7.38,0,0,1,4.06-1.12,6.94,6.94,0,0,1,4.87,1.76,6.44,6.44,0,0,1,2,4.64h-1.91a4.85,4.85,0,0,0-1.52-3.41,5.43,5.43,0,0,0-7.67.65,8.79,8.79,0,0,0-1.48,5.45v.58a8.6,8.6,0,0,0,1.48,5.36A5,5,0,0,0,151.58,85.53Z"
						/>
						<path
							className="cls-3"
							d="M166.72,77.83,164.44,80v6.86h-2V61.38h2V77.7l1.88-2,6.87-6.75h2.51l-7.62,7.57,8.28,10.39H174Z"
						/>
						<path
							className="cls-3"
							d="M180.91,68.92l.07,2.92a6.53,6.53,0,0,1,2.49-2.44,6.83,6.83,0,0,1,3.32-.82c2.83,0,4.63,1.17,5.39,3.49a6.73,6.73,0,0,1,2.58-2.58,7.16,7.16,0,0,1,3.55-.91q5.77,0,5.89,6.31v12h-2V75a5.37,5.37,0,0,0-1-3.57,4.23,4.23,0,0,0-3.32-1.16,5.24,5.24,0,0,0-3.62,1.39A4.88,4.88,0,0,0,192.58,75V86.88h-2v-12a4.87,4.87,0,0,0-1.09-3.44,4.31,4.31,0,0,0-3.29-1.14A5,5,0,0,0,183,71.38a6.38,6.38,0,0,0-2,3.18V86.88h-2v-18Z"
						/>
						<path
							className="cls-3"
							d="M220.57,86.88a9.62,9.62,0,0,1-.38-2.51,6.88,6.88,0,0,1-2.66,2.1,8.23,8.23,0,0,1-3.43.74,6,6,0,0,1-4.19-1.44,4.72,4.72,0,0,1-1.61-3.66A4.82,4.82,0,0,1,210.49,78a10.53,10.53,0,0,1,6.08-1.52h3.6v-2a4,4,0,0,0-1.18-3,4.94,4.94,0,0,0-3.46-1.1,5.47,5.47,0,0,0-3.44,1.06,3.15,3.15,0,0,0-1.36,2.56l-2,0a4.63,4.63,0,0,1,2-3.71,7.71,7.71,0,0,1,4.9-1.57,6.92,6.92,0,0,1,4.73,1.51,5.37,5.37,0,0,1,1.79,4.19v8.5a10.41,10.41,0,0,0,.55,3.9v.2Zm-6.24-1.43a6.66,6.66,0,0,0,3.56-1,5.6,5.6,0,0,0,2.28-2.57V78h-3.55A9,9,0,0,0,212,79.05,3.27,3.27,0,0,0,210.3,82a3.21,3.21,0,0,0,1.12,2.51A4.23,4.23,0,0,0,214.33,85.45Z"
						/>
						<path
							className="cls-3"
							d="M234.09,85.53a5.22,5.22,0,0,0,3.47-1.17,4.08,4.08,0,0,0,1.52-3H241a5.58,5.58,0,0,1-1,3,6.5,6.5,0,0,1-2.51,2.12,7.59,7.59,0,0,1-3.36.76,7,7,0,0,1-5.61-2.46,10,10,0,0,1-2.06-6.6v-.6a11.23,11.23,0,0,1,.93-4.7A7.12,7.12,0,0,1,230,69.7a7.38,7.38,0,0,1,4.06-1.12,6.94,6.94,0,0,1,4.87,1.76A6.44,6.44,0,0,1,241,75h-1.91a4.8,4.8,0,0,0-5-4.7,5,5,0,0,0-4.18,1.94,8.79,8.79,0,0,0-1.48,5.45v.58a8.6,8.6,0,0,0,1.48,5.36A5,5,0,0,0,234.09,85.53Z"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
}

export default Logo;
